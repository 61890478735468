<template>
  <v-app>
    <!-- <pages-core-app-bar /> -->

    <pages-core-drawer
      v-if="$vuetify.breakpoint.xsOnly"
      :expand-on-hover.sync="expandOnHover"
    />

    <pages-core-view />

    <pages-core-footer />
  </v-app>
</template>

<script>
export default {
  name: "PagesIndex",

  components: {
    // PagesCoreAppBar: () => import("../../components/AppBar"),
    PagesCoreFooter: () => import("../internal/Footer"),
    PagesCoreView: () => import("../internal/View"),
    PagesCoreDrawer: () => import("../internal/Drawer"),
  },

  data: () => ({
    expandOnHover: false,
  }),

  methods: {},

  created() {
    //this.$vuetify.theme.dark = true;
    //this.$vuetify.theme.dark = true;
  },

  beforeDestroy() {
    //this.$vuetify.theme.dark = false;
  },
};
</script>
